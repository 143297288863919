import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.scss";
import { Vue } from "vue-class-component";

const mapModuleContainerId = "map-module";
const rootContainer = document.getElementById(mapModuleContainerId);

if (rootContainer != null) {
    const playgroundsRaw = rootContainer.dataset.playgrounds;

    if (undefined === playgroundsRaw) {
        throw "map module is missing playground data.";
    }

    const playgrounds = JSON.parse(playgroundsRaw);

    const i18nRaw = rootContainer.dataset.i18n;

    if (undefined === i18nRaw) {
        throw "map module is missing i18n data.";
    }

    const i18n = JSON.parse(i18nRaw);

    const app = createApp(App, { playgrounds: playgrounds });
    app.config.globalProperties.$i18n = i18n;

    app.use(store).use(router).mount(`#${mapModuleContainerId}`);
} else {
    throw `The map-module script is loaded, but there is no element with the id ${mapModuleContainerId}`;
}
