export type State = {
    activeModules: Array<string>;
    accessToken: string;
    style: string;
};

export const clientConfigurationStoreModule = {
    namespaced: true,
    state: {
        activeModules: ["playground"],
        accessToken:
            "pk.eyJ1IjoicHNwLW1hcHMtYmFja2VuZCIsImEiOiJjbDc0eDl0NWUwNDh6M3Z0OHk4eThtOHF4In0.8Nyw6AIFG1-sEsVVb7ZAlQ",
        style: "mapbox://styles/psp-maps-backend/clfmigteq002601t6tnpyk38s",
    },
};
