const mapboxgl = require("mapbox-gl");

export default {
    playgroundUuids(state) {
        return state.playgrounds.map(
            (playground) => playground.properties.uuid,
        );
    },
    playgroundByUuid: (state) => (uuid) => {
        return state.playgrounds.find(
            (playground) => playground.properties.uuid === uuid,
        );
    },
    bounds: (state) => () => {
        if (0 === state.playgrounds.length) {
            return null;
        }

        const bounds = new mapboxgl.LngLat(
            state.playgrounds[0].geometry.coordinates[0],
            state.playgrounds[0].geometry.coordinates[1],
        ).toBounds(10);

        for (const playground of state.playgrounds) {
            bounds.extend(
                new mapboxgl.LngLat(
                    playground.geometry.coordinates[0],
                    playground.geometry.coordinates[1],
                ).toBounds(10),
            );
        }

        return bounds;
    },
};
