import { createStore } from "vuex";

import { playgroundStoreModule } from "@/Layers/Playgrounds/Store/playground_store";
import { clientConfigurationStoreModule } from "./ClientConfiguration/client_configuration_store";

export interface State {
    focus: null | object;
    allowInteraction: boolean;
}

export default createStore<State>({
    state: {
        focus: null,
        allowInteraction: true,
    },
    modules: {
        playground: playgroundStoreModule,
        clientConfiguration: clientConfigurationStoreModule,
    },
    mutations: {
        center(state, { coordinates, allowInteraction }) {
            state.focus = {
                center: coordinates,
                bounds: null,
            };
            state.allowInteraction = allowInteraction;
        },
        fitBounds(state, { bounds, allowInteraction }) {
            state.focus = {
                center: null,
                bounds: bounds,
            };
            state.allowInteraction = allowInteraction;
        },
        clear(state) {
            state.focus = null;
        },
        allowInteraction(state, allowInteraction) {
            state.allowInteraction = allowInteraction;
        },
    },
});
