<template>
    <Map :style="style" :access-token="accessToken"></Map>
</template>

<script>
import Map from "./Map.vue";
import { createNamespacedHelpers, mapActions } from "vuex";

const { mapState } = createNamespacedHelpers("clientConfiguration");

export default {
    methods: {
        ...mapActions({
            initializePlaygroundStore: "playground/initializePlaygroundStore",
        }),
    },
    name: "App",
    props: {
        playgrounds: Object,
    },
    computed: {
        ...mapState(["style", "accessToken"]),
    },
    components: { Map },
    created() {
        this.initializePlaygroundStore(this.playgrounds);
    },
};
</script>
