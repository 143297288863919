<template>
    <div class="absolute p-5 top-0 w-full md:w-3/4 h-full">
        <div
            class="w-full h-full z-10 bg-white rounded shadow-lg p-5 overflow-hidden flex flex-col"
        >
            <div class="flex justify-between">
                <h3 class="text-sm font-medium">
                    <h3 class="">{{ title }}</h3>
                </h3>
                <button
                    type="button"
                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="close"
                >
                    <span class="sr-only">Close</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div class="relative py-4 flex-1 space-y-5 overflow-scroll">
                <p v-if="description !== null" class="text-sm text-gray-500">
                    {{ description }}
                </p>

                <div class="grid grid-cols-2 gap-4">
                    <div>
                        <p class="mb-1.5 text-sm font-bold text-gray-500">
                            {{
                                this.$i18n[
                                    "map.detail.section.address.subheading"
                                ]
                            }}
                        </p>

                        <p class="text-sm text-gray-500">
                            {{ address.street }}
                        </p>
                        <p class="text-sm text-gray-500">
                            {{ address.locality }}
                        </p>
                        <p class="text-sm text-gray-500">
                            {{ address.country }}
                        </p>
                    </div>
                    <div>
                        <a :href="url">
                            <div
                                class="space-x-5 bg-psp-purple hover:bg-psp-purple inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                            >
                                <span>{{
                                    this.$i18n["map.detail.section.cta.label"]
                                }}</span>
                                <ExternalLinkIcon
                                    class="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </div>
                        </a>
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-4">
                    <div>
                        <p class="mb-1.5 text-sm font-bold text-gray-500">
                            {{
                                this.$i18n[
                                    "map.detail.section.inspections.subheading"
                                ]
                            }}
                        </p>

                        <div>
                            <p
                                v-if="inspectionResult === null"
                                class="mt-2.5 text-sm font-medium text-gray-500 pl-2 border-l-4 border-gray-400"
                            >
                                {{
                                    this.$i18n[
                                        "map.detail.section.inspections.empty_state.text"
                                    ]
                                }}
                            </p>

                            <p
                                v-if="lastFinalizedInspections.yearly !== null"
                                class="text-sm text-gray-500"
                            >
                                {{
                                    this.$i18n[
                                        "map.detail.section.inspections.last_inspection.yearly.label"
                                    ]
                                }}:
                                {{
                                    new Date(
                                        lastFinalizedInspections.yearly,
                                    ).toLocaleDateString()
                                }}
                            </p>

                            <p
                                v-if="
                                    lastFinalizedInspections.operative !== null
                                "
                                class="text-sm text-gray-500"
                            >
                                {{
                                    this.$i18n[
                                        "map.detail.section.inspections.last_inspection.operative.label"
                                    ]
                                }}:
                                {{
                                    new Date(
                                        lastFinalizedInspections.operative,
                                    ).toLocaleDateString()
                                }}
                            </p>
                            <p
                                v-if="
                                    lastFinalizedInspections.extendedRoutine !==
                                    null
                                "
                                class="text-sm text-gray-500"
                            >
                                {{
                                    this.$i18n[
                                        "map.detail.section.inspections.last_inspection.extended_routine.label"
                                    ]
                                }}:
                                {{
                                    new Date(
                                        lastFinalizedInspections.extendedRoutine,
                                    ).toLocaleDateString()
                                }}
                            </p>
                        </div>
                    </div>

                    <div v-if="inspectionResult !== null">
                        <p class="mb-1.5 text-sm font-bold text-gray-500">
                            {{
                                this.$i18n[
                                    "map.detail.section.tasks.subheading"
                                ]
                            }}:
                        </p>
                        <p
                            v-if="openTaskType1Count > 0"
                            class="mt-2.5 text-sm font-medium text-gray-500 pl-2 border-l-4 border-deficiency-type-1"
                        >
                            {{
                                this.$i18n[
                                    "map.detail.section.tasks.deficiency_type_1.count.label"
                                ]
                            }}:
                            {{ openTaskType1Count }}
                        </p>
                        <p
                            v-if="openTaskType2Count > 0"
                            class="mt-2.5 text-sm font-medium text-gray-500 pl-2 border-l-4 border-deficiency-type-2"
                        >
                            {{
                                this.$i18n[
                                    "map.detail.section.tasks.deficiency_type_2.count.label"
                                ]
                            }}:
                            {{ openTaskType2Count }}
                        </p>

                        <p
                            v-if="openTaskType3Count > 0"
                            class="mt-2.5 text-sm font-medium text-gray-500 pl-2 border-l-4 border-deficiency-type-3"
                        >
                            {{
                                this.$i18n[
                                    "map.detail.section.tasks.deficiency_type_3.count.label"
                                ]
                            }}:
                            {{ openTaskType3Count }}
                        </p>

                        <p
                            v-if="
                                openTaskType3Count === 0 &&
                                openTaskType2Count === 0 &&
                                openTaskType1Count === 0
                            "
                            class="mt-2.5 text-sm font-medium text-gray-500 pl-2 border-l-4 border-no-deficiency"
                        >
                            {{
                                this.$i18n[
                                    "map.detail.section.tasks.empty_state.text"
                                ]
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { XIcon, ExternalLinkIcon } from "@heroicons/vue/outline";

export default {
    name: "PlaygroundDetailView",
    props: {
        playgroundUuid: String,
    },
    computed: {
        playground() {
            return this.$store.getters["playground/playgroundByUuid"](
                this.playgroundUuid,
            );
        },
        title() {
            return this.playground.properties.title;
        },
        description() {
            return this.playground.properties.description;
        },
        address() {
            return this.playground.properties.address;
        },
        lastFinalizedInspections() {
            return this.playground.properties.lastFinalizedInspections;
        },
        url() {
            return this.playground.properties.url;
        },
        openTaskType3Count() {
            return this.playground.properties.openTaskType3Count;
        },
        openTaskType2Count() {
            return this.playground.properties.openTaskType2Count;
        },
        openTaskType1Count() {
            return this.playground.properties.openTaskType1Count;
        },
        inspectionResult() {
            return this.playground.properties.inspectionResult;
        },
    },
    methods: {
        center: function () {
            this.$store.commit("center", {
                coordinates: this.playground.geometry.coordinates,
                allowInteraction: false,
            });
        },
        close: function () {
            this.$router.push({
                name: "home",
            });
        },
    },
    updated: function () {
        this.center();
    },
    mounted: function () {
        this.center();
    },
    unmounted() {
        this.$store.commit("clear");
    },
    components: { XIcon, ExternalLinkIcon },
};
</script>
