import * as actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export type State = {
    playgrounds: any;
};

export const playgroundStoreModule = {
    namespaced: true,
    state: {
        playgrounds: [],
    },
    getters: {
        ...getters,
    },
    mutations: {
        ...mutations,
    },
    actions,
};
