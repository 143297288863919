<template>
    <div
        v-if="null !== popupText"
        class="absolute top-0 right-0 w-11/12 md:w-1/5 z-10 bg-white rounded shadow-lg p-5 m-5 overflow-hidden"
    >
        {{ popupText }}
    </div>
</template>

<script>
import { LngLatBounds, Map, Marker } from "mapbox-gl";
import { mapState, mapGetters, mapActions } from "vuex";
import svgFail from "@/assets/playgrounds/marker_fail.svg";
import svgPass from "@/assets/playgrounds/marker_pass.svg";
import svgWarn from "@/assets/playgrounds/marker_warn.svg";
import svgNotInspected from "@/assets/playgrounds/marker_not_inspected.svg";

export default {
    name: "Playgrounds",
    props: {
        map: Map,
        pspConfiguration: Object,
    },
    data() {
        return {
            popupText: null,
        };
    },
    methods: {
        showPopup: function (text) {
            this.popupText = text;
        },

        clearPopup: function () {
            this.popupText = null;
        },
        ...mapActions({
            initializePlaygroundStore: "playground/initializePlaygroundStore",
        }),
        initializeMap: function () {
            const COLOR_PASS = "#86efac";
            const COLOR_WARN = "#fde047";
            const COLOR_FAIL = "#fca5a5";
            const COLOR_NOT_INSPECTED = "#9ca3af";

            const colors = [
                COLOR_PASS,
                COLOR_WARN,
                COLOR_FAIL,
                COLOR_NOT_INSPECTED,
            ];
            const map = this.map;
            const store = this.$store;

            map.on("load", () => {
                map.addSource("playground_source", {
                    type: "geojson",
                    promoteId: "uuid",
                    data: {
                        type: "FeatureCollection",
                        features: this.playgrounds,
                    },
                    cluster: true,
                    clusterMaxZoom: 16, // Max zoom to cluster points on
                    clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
                    clusterProperties: {
                        clusterWarnCount: [
                            "+",
                            [
                                "case",
                                ["==", ["get", "inspectionResult"], "warn"],
                                1,
                                0,
                            ],
                        ],
                        clusterFailCount: [
                            "+",
                            [
                                "case",
                                ["==", ["get", "inspectionResult"], "fail"],
                                1,
                                0,
                            ],
                        ],
                        clusterPassCount: [
                            "+",
                            [
                                "case",
                                ["==", ["get", "inspectionResult"], "pass"],
                                1,
                                0,
                            ],
                        ],
                        clusterNotInspectedCount: [
                            "+",
                            [
                                "case",
                                ["==", ["get", "inspectionResult"], null],
                                1,
                                0,
                            ],
                        ],
                    },
                });

                function loader(items, allDone) {
                    let count = Object.keys(items).length;
                    const thingToDoCompleted = () => {
                        count--;
                        if (0 === count) {
                            allDone();
                        }
                    };

                    for (const [name, svg] of Object.entries(items)) {
                        loadImage(name, svg, thingToDoCompleted);
                    }
                }

                function loadImage(name, svg, onComplete) {
                    const markerImage = new Image();

                    markerImage.onload = () => {
                        map.addImage(name, markerImage);
                        onComplete();
                    };
                    markerImage.src = svg;
                    markerImage.crossOrigin = "Anonymous";
                }

                const items = {
                    markerPass: svgPass,
                    markerWarn: svgWarn,
                    markerFail: svgFail,
                    markerNotInspected: svgNotInspected,
                };

                loader(items, function () {
                    return map.addLayer({
                        id: "playground_layer",
                        type: "symbol",
                        source: "playground_source",
                        filter: ["!", ["has", "point_count"]],
                        layout: {
                            "icon-image": [
                                "match",
                                ["get", "inspectionResult"],
                                "fail",
                                "markerFail",
                                "warn",
                                "markerWarn",
                                "pass",
                                "markerPass",
                                /* other */ "markerNotInspected",
                            ],
                            "icon-anchor": "bottom",
                            "icon-size": [
                                "interpolate",
                                ["linear"],
                                ["zoom"],
                                5,
                                0.25,
                                16,
                                1,
                            ],
                            "text-field": [
                                "case",
                                ["==", ["get", "openTaskTotalCount"], 0],
                                "",
                                ["to-string", ["get", "openTaskTotalCount"]],
                            ],
                            "text-font": [
                                "DIN Offc Pro Medium",
                                "Arial Unicode MS Bold",
                            ],
                            "text-size": [
                                "interpolate",
                                ["linear"],
                                ["zoom"],
                                5,
                                6,
                                16,
                                15,
                            ],
                            "text-offset": [0, -1.2],
                        },
                        paint: {
                            "text-color": "#ffffff",
                        },
                    });
                });

                function updateMarkers() {
                    const newMarkers = {};
                    const features =
                        map.querySourceFeatures("playground_source");

                    // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
                    // and add it to the map if it's not there already
                    for (const feature of features) {
                        const coords = feature.geometry.coordinates;
                        const props = feature.properties;
                        if (!props.cluster) continue;
                        const id = props.cluster_id;

                        let marker = markers[id];
                        if (!marker) {
                            const el = createDonutChart(props);
                            el.addEventListener("click", () => {
                                map.getSource(
                                    "playground_source",
                                ).getClusterLeaves(
                                    id,
                                    100,
                                    0,
                                    (error, features) => {
                                        let bounds = new LngLatBounds();

                                        for (const feature of features) {
                                            bounds.extend(
                                                feature.geometry.coordinates,
                                            );
                                        }

                                        store.commit("fitBounds", {
                                            bounds: bounds,
                                            allowInteraction: true,
                                        });
                                    },
                                );
                            });
                            marker = markers[id] = new Marker({
                                element: el,
                            }).setLngLat(coords);
                        }
                        newMarkers[id] = marker;

                        if (!markersOnScreen[id]) marker.addTo(map);
                    }
                    // for every marker we've added previously, remove those that are no longer visible
                    for (const id in markersOnScreen) {
                        if (!newMarkers[id]) markersOnScreen[id].remove();
                    }
                    markersOnScreen = newMarkers;
                }

                // after the GeoJSON data is loaded, update markers on the screen on every frame
                map.on("render", () => {
                    if (!map.isSourceLoaded("playground_source")) return;
                    updateMarkers();
                });

                const markers = {};
                let markersOnScreen = {};
            });

            function createDonutChart(props) {
                const offsets = [];
                const counts = [
                    props.clusterPassCount,
                    props.clusterWarnCount,
                    props.clusterFailCount,
                    props.clusterNotInspectedCount,
                ];
                let total = 0;
                for (const count of counts) {
                    offsets.push(total);
                    total += count;
                }
                const fontSize =
                    total >= 128 ? 18 : 12.5 + Math.log2(16 * total) / 2;
                const r = total >= 128 ? 33 : 3 * Math.log2(16 * total);
                const r0 = Math.round(r * 0.6);
                const w = r * 2;

                let html = `<div>
<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

                for (let i = 0; i < counts.length; i++) {
                    html += donutSegment(
                        offsets[i] / total,
                        (offsets[i] + counts[i]) / total,
                        r,
                        r0,
                        colors[i],
                    );
                }
                html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
<text dominant-baseline="central" transform="translate(${r}, ${r})">
${total.toLocaleString()}
</text>
</svg>
</div>`;

                const el = document.createElement("div");
                el.innerHTML = html;
                return el.firstChild;
            }

            function donutSegment(start, end, r, r0, color) {
                if (end - start === 1) end -= 0.00001;
                const a0 = 2 * Math.PI * (start - 0.25);
                const a1 = 2 * Math.PI * (end - 0.25);
                const x0 = Math.cos(a0),
                    y0 = Math.sin(a0);
                const x1 = Math.cos(a1),
                    y1 = Math.sin(a1);
                const largeArc = end - start > 0.5 ? 1 : 0;

                // draw an SVG path
                return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${
                    r + r * x0
                } ${r + r * y0} A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${
                    r + r * y1
                } L ${r + r0 * x1} ${
                    r + r0 * y1
                } A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${
                    r + r0 * y0
                }" fill="${color}" />`;
            }
            map.on("mouseenter", "playground_layer", (e) => {
                map.getCanvas().style.cursor = "pointer";
                const title = e.features[0].properties.title;
                this.showPopup(title);
            });
            map.on("mouseleave", "playground_layer", () => {
                map.getCanvas().style.cursor = "";
                this.clearPopup();
            });
            map.on("click", "playground_layer", (e) => {
                this.$router.push({
                    name: "playground_detail",
                    params: { playgroundUuid: e.features[0].properties.uuid },
                });
            });
        },
    },
    computed: {
        ...mapState("playground", ["playgrounds"]),
        ...mapGetters("playground", ["playgroundUuids"]),
    },
    watch: {
        map: {
            handler(map) {
                if (null !== map) {
                    this.initializeMap();
                }
            },
        },
    },
};
</script>

<style></style>
