<template>
    <div class="relative h-full">
        <div id="map" class="h-full"></div>
        <Playgrounds :map="map"></Playgrounds>
        <router-view v-slot="{ Component }">
            <transition
                enter-active-class="duration-2000 ease-out"
                enter-from-class="opacity-0 -translate-x-full"
                enter-to-class="opacity90"
                leave-active-class="duration-2000 ease-in"
                leave-from-class="opacity-90"
                leave-to-class="opacity-0 -translate-x-full"
            >
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import Playgrounds from "./Layers/Playgrounds/Playgrounds.vue";
import { mapState } from "vuex";

export default {
    name: "Map",
    props: {
        style: String,
        accessToken: String,
    },
    computed: {
        ...mapState(["focus", "allowInteraction"]),
        allItemsBounds: function () {
            const boundsCollection = [];

            const bounds = this.$store.getters[`playground/bounds`]();
            if (null !== bounds) {
                boundsCollection.push(bounds);
            }

            let combinedBounds = null;

            for (const boundsItem of boundsCollection) {
                if (null === combinedBounds) {
                    combinedBounds = boundsItem;
                } else {
                    combinedBounds.extend(boundsItem);
                }
            }

            return combinedBounds;
        },
        mapBounds: function () {
            const allItemsBounds = this.allItemsBounds;

            const center = allItemsBounds.getCenter();
            const radius =
                allItemsBounds.getSouthEast().distanceTo(center) + 200;

            return center.toBounds(radius * 1.5);
        },
    },
    data: () => ({
        map: null,
        showPlayground: true,
        triggeredMoving: false,
    }),
    watch: {
        focus: {
            deep: true,
            handler(newFocus) {
                if (null !== newFocus) {
                    if (null !== newFocus.center) {
                        this.flyTo(newFocus.center);
                    }
                    if (null !== newFocus.bounds) {
                        this.fitBounds(newFocus.bounds);
                    }
                } else {
                    this.resetPadding();
                }
            },
        },
        allowInteraction: {
            handler(allowInteraction) {
                if (this.triggeredMoving) {
                    this.disableInteraction();
                } else if (allowInteraction) {
                    this.enableInteraction();
                }
            },
        },
    },

    methods: {
        initializeMap: function () {
            mapboxgl.accessToken = this.accessToken;

            let mapOptions = {
                container: "map",
                style: this.style,
                maxBounds: this.mapBounds,
                bounds: this.allItemsBounds,
                logoPosition: "bottom-right",
                maxZoom: 18,
                fitBoundsOptions: {
                    padding: { top: 50, bottom: 50, left: 50, right: 50 },
                    pitch: 60,
                },
                projection: "globe",
            };

            if (null !== (this.focus?.bounds ?? null)) {
                Object.assign(mapOptions, {
                    bounds: this.focus.bounds,
                });
            }

            if (null !== (this.focus?.center ?? null)) {
                Object.assign(mapOptions, {
                    zoom: 14,
                    center: this.focus.center,
                });
            }

            const map = new mapboxgl.Map(mapOptions);

            // map.showPadding = true;

            map.setPadding({ top: 50, bottom: 50, left: 50, right: 50 });

            map.dragRotate.disable();
            map.touchZoomRotate.disableRotation();
            map.boxZoom.disable();
            map.keyboard.disable();
            map.doubleClickZoom.disable();
            map.dragPan.disable();

            map.on("moveend", () => {
                if (this.allowInteraction) {
                    this.enableInteraction();
                }
            });

            // Bug: Navigation Control allows zooming outside of max bounds
            // map.addControl(
            //     new mapboxgl.NavigationControl({ showCompass: false }),
            //     "bottom-right"
            // );

            this.map = map;
        },
        flyTo: function (center) {
            this.triggeredMoving = true;

            this.$store.commit("allowInteraction", false);

            const canvas = this.map.getCanvas();
            const devicePixelRatio = window.devicePixelRatio;
            const w = canvas.width / devicePixelRatio;
            const h = canvas.height / devicePixelRatio;

            const x = (7 * w) / 8;
            const y = h / 2;

            const [paddingLeft, paddingRight] =
                2 * x > w ? [2 * x - w, 0] : [0, -2 * x + w];
            const [paddingTop, paddingBottom] =
                2 * y > h ? [2 * y - h, 0] : [0, -2 * y + h];

            this.map.flyTo({
                center: center,
                zoom: 18,
                essential: true,
                padding: {
                    top: paddingTop,
                    bottom: paddingBottom,
                    left: paddingLeft,
                    right: paddingRight,
                },
                duration: 2000,
            });
        },
        fitBounds: function (bounds) {
            this.triggeredMoving = true;
            this.disableInteraction();
            this.map.fitBounds(bounds, {
                duration: 2000,
                pitch: 60,
                padding: { top: 50, bottom: 50, left: 50, right: 50 },
            });
        },
        enableInteraction: function () {
            this.map.scrollZoom.enable();
            this.map.dragPan.enable();
        },
        disableInteraction: function () {
            this.map.scrollZoom.disable();
            this.map.dragPan.disable();
        },
        resetPadding: function () {
            this.map.flyTo({
                padding: { top: 50, bottom: 50, left: 50, right: 50 },
                zoom: this.map.getZoom() - 1,
                essential: true,
                duration: 2000,
            });

            this.$store.commit("allowInteraction", true);
            this.triggeredMoving = true;
        },
    },
    components: { Playgrounds },

    mounted() {
        this.initializeMap();
    },
};
</script>
